appearance() {
  -webkit-appearance: arguments;
     -moz-appearance: arguments;
          appearance: arguments;
}

user-select() {
  -webkit-user-select: arguments;
     -moz-user-select: arguments;
      -ms-user-select: arguments;
          user-select: arguments;
}

box-sizing() {
  -webkit-box-sizing: arguments;  // Android2.1-2.3
     -moz-box-sizing: arguments;  // FF28-
          box-sizing: arguments;
}

box-shadow() {
  -webkit-box-shadow: arguments;  // Android2.1-2.3
          box-shadow: arguments;
}

border-image() {
  -webkit-border-image: arguments;
          border-image: arguments;
}

border-image-source() {
  -webkit-border-image-source: arguments;
          border-image-source: arguments;
}

border-image-slice() {
  -webkit-border-image-slice: arguments;
          border-image-slice: arguments;
}

border-image-width() {
  -webkit-border-image-source: arguments;
          border-image-source: arguments;
}

border-image-outset() {
  -webkit-border-image-outset: arguments;
          border-image-outset: arguments;
}

border-image-repeat() {
  -webkit-border-image-repeat: arguments;
          border-image-repeat: arguments;
}


transform() {
    -webkit-transform: arguments;
        -ms-transform: arguments;
            transform: arguments;
}

transform-origin() {
    -webkit-transform-origin: arguments;
        -ms-transform-origin: arguments;
            transform-origin: arguments;
}

transition() {
  -webkit-transition: arguments;
          transition: arguments;
}

transition-property() {
  -webkit-transition-property: arguments;
          transition-property: arguments;
}

transition-duration() {
  -webkit-transition-duration: arguments;
          transition-duration: arguments;
}

transition-timing-function() {
  -webkit-transition-timing-function: arguments;
          transition-timing-function: arguments;
}

transition-delay() {
  -webkit-transition-delay: arguments;
          transition-delay: arguments;
}

animation() {
  -webkit-animation: arguments;
          animation: arguments;
}

animation-name() {
  -webkit-animation-name: arguments;
          animation-name: arguments;
}

animation-duration() {
  -webkit-animation-duration: arguments;
          animation-duration: arguments;
}

animation-timing-function() {
  -webkit-animation-timing-function: arguments;
          animation-timing-function: arguments;
}

animation-delay() {
  -webkit-animation-delay: arguments;
          animation-delay: arguments;
}

animation-iteration-count() {
  -webkit-animation-iteration-count: arguments;
          animation-iteration-count: arguments;
}

animation-direction() {
  -webkit-animation-direction: arguments;
          animation-direction: arguments;
}

animation-play-state() {
  -webkit-animation-play-state: arguments;
          animation-play-state: arguments;
}

animation-fill-mode() {
  -webkit-animation-fill-mode: arguments;
          animation-fill-mode: arguments;
}
