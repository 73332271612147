/*! animate */

@require "mixins"

vendors = webkit official


.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}


@keyframes fadeOutLeft {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutRight {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}
.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeInLeft {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    transform: none;
  }
}
.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: none;
  }
}
.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
.fadeOutDown {
  animation-name: fadeOutDown;
}

