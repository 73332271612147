/***********
 * reset
 ***********/
* {box-sizing: border-box;}
body,h1,h2,h3,h4,h5,h6,ul,ol,li,p,hr,fieldset,form,input,select,textarea,button,dl,dt,dd{
  margin: 0;
  padding: 0;
  border: 0;
  font-weight:normal;
}
body,input,select,textarea,button {
    font-size:2.8rem;
    font-family: Helvetica, Arial, 'Microsoft Yahei';
    outline:0;
}
html,body {width: 100%; height:100%;}
ul,li {list-style: none;}
b,i {font-style:normal; font-weight: 400;}
img {border: 0; max-width: 100%;}
input,button,img,label {vertical-align: middle;}
table {border-collapse:collapse; border-spacing:0;}
body {
    //line-height: 1;
    margin:0;
    min-width:320px;
    -ms-touch-action: none;
    overflow: hidden;
}
a {text-decoration: none;}


  .am-modal-header .am-modal-title {
    font-size: 15px;
  }

  .am-modal-footer  .am-modal-button {
    font-size: 17px;
  }
